import React from 'react';

interface SvgComponentProps {
  color?: string;
}

const LeftBorderPrice: React.FC<SvgComponentProps> = ({ color }) => (
  <svg
    width="9.491455"
    height="32.000000"
    viewBox="0 0 9.49146 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs />
    <path
      id="Rectangle 1501"
      d="M7.22461 0L9.49146 0L9.49146 32L4.00073 32C1.58008 32 -0.285889 29.8666 0.036377 27.4675L3.26025 3.46747C3.52686 1.48199 5.22119 0 7.22461 0Z"
      fill={color || '#0C1027'}
      fillRule="nonzero"
    />
  </svg>
);

export default LeftBorderPrice;
