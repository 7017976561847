import React from 'react';
import Image from 'next/image';
import priceIcon from '@/assets/icons/give/priceIcon.svg';
import styles from './PriceComponent.module.scss';
import HyperRub from '@/assets/icons/hyperRub';
import LeftBorderPrice from '@/assets/icons/give/LeftBorderPrice';
import RightBorderPrice from '@/assets/icons/give/RightBorderPrice';

interface IChallengeDropCard {
  price: number | null | undefined | string;
}

const PriceComponent = ({ price }: IChallengeDropCard) => {
  return (
    <div className={styles.main_price}>
      <LeftBorderPrice />
      <div className={styles.container_price}>
        <Image src={priceIcon} alt="icon_price" />
        <p className={styles.price}>
          {price} <HyperRub />
        </p>
      </div>
      <RightBorderPrice />
    </div>
  );
};

export default PriceComponent;
