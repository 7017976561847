import React from 'react';

interface SvgComponentProps {
  color?: string;
}

const rightBorderPrice: React.FC<SvgComponentProps> = ({ color }) => (
  <svg
    width="9.491455"
    height="32.000000"
    viewBox="0 0 9.49146 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.26685 32L0 32L0 0L5.49072 0C7.91138 0 9.77734 2.13336 9.45508 4.53253L6.2312 28.5325C5.9646 30.518 4.27026 32 2.26685 32Z"
      fill={color || '#0C1027'}
      fillRule="nonzero"
    />
  </svg>
);

export default rightBorderPrice;
